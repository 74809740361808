import type { CurrencyCode, UnknownEvent } from '@faststore/sdk'
import { sendAnalyticsEvent, useSession } from '@faststore/sdk'
import { graphql } from 'gatsby'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useModal } from 'src/sdk/ui/modal/useModal'
import AttributesPDP from './AttributesPDP'
import BadgesPDP from './BadgesPDP'
import BreadcrumbPDP from './BreadcrumbPDP'
import ImagePDP from './ImagePDP'
import PricePDP from './PricePDP'
import Purchase from './Purchase'
import SpecificationsPDP from './SpecificationsPDP'
import StickyCart from './StickyCart'
import SubscriptionInfo from './SubscriptionInfo'
import TitlePDP from './TitlePDP'
import type {
  AdditionalProperty,
  DefaultSku,
  ExtendedProductDetails,
} from './typings'
import { getPriceInfo, getProductSpecifications } from './utilities'
import OutOfStockProduct from './OutOfStockProduct'
import { getLoyaltyMultiplier } from 'src/components/cart/CartSidebar/utils/utils'

interface Props {
  product: ExtendedProductDetails
}
export interface ImageElementData {
  url: string
  alternateName: string
}

function NewProductDetails({ product: staleProduct }: Props) {
  const { currency } = useSession()
  const { openAddToCart, handleOpenCloseAddToCart } = useModal()
  const [isAddedFromSticky, setIsAddedFromSticky] = useState<boolean>(false)
  const [loyaltyMultiplier, setLoyaltyMultiplier] = useState<
    number | undefined
  >(undefined)

  const fetchRef = useRef(false)

  const {
    sku,
    gtin,
    name: variantName,
    brand,
    isVariantOf,
    isVariantOf: { additionalProperty, hasVariant },
    offers: {
      lowPrice,
      offers: [{ availability, price, listPrice, seller }],
    },
    breadcrumbList: breadcrumbs,
    stockKeepingUnit,
    productBadges,
    productSpecifications,
  } = staleProduct

  const isAvailable = availability !== 'https://schema.org/OutOfStock'

  // Remove the name from the breadcrumb last level
  const breadcrumbList = breadcrumbs.itemListElement.map((c) => c)

  breadcrumbList[breadcrumbList.length - 1] = {
    ...breadcrumbList[breadcrumbList.length - 1],
    name: '',
  }

  const additionalPropertiesToShow = useMemo(() => {
    const shallowAdditionalProps = additionalProperty.map(
      (el: AdditionalProperty) => ({
        ...el,
      })
    )

    return getProductSpecifications(shallowAdditionalProps)
  }, [additionalProperty])

  const hasSubscription = !!stockKeepingUnit?.attachments?.subscriptions?.length

  const priceInfo = getPriceInfo(
    listPrice,
    lowPrice,
    productSpecifications?.unitMultiplier
  )

  const defaultSku: DefaultSku = {
    sku: staleProduct.sku,
    availability:
      staleProduct.offers.offers[0].availability !==
      'https://schema.org/OutOfStock',
    name: staleProduct.name,
    url: staleProduct.image[0]?.url,
    hasMoreThanOneVariant: hasVariant?.length > 1,
  }

  useEffect(() => {
    if (sku) {
      const itemCategory = breadcrumbList?.slice(0, -1).map((x) => x.name)
      const isKit = stockKeepingUnit?.isKit

      sendAnalyticsEvent<UnknownEvent>({
        name: 'view_item',
        params: {
          // Adding optional kit parameters
          ...(isKit && {
            bundle_label: isVariantOf.name,
            bundle_discount: +(listPrice - price).toFixed(2),
            bundle_discount_percentage: +(1 - price / listPrice).toFixed(2),
          }),
          items: [
            {
              item_category: itemCategory,
              item_id: isVariantOf.productGroupID,
              item_name: isVariantOf.name,
              item_brand: brand.name,
              item_list_name: itemCategory.join(' '),
              item_list_id: itemCategory.join(' '),
              item_variant: sku,
              price,
              discount: +(listPrice - price).toFixed(2),
              full_price: listPrice,
              discount_percentage: +(1 - price / listPrice).toFixed(2),
              currency: currency.code as CurrencyCode,
              item_variant_name: variantName,
              product_reference_id: Number(gtin),
              index: 0,
              quantity: 1,
              availability: availability.replace('https://schema.org', ''),
              coupon: null,
              // Adding optional kit parameters
              ...(isKit && {
                bundle_label: isVariantOf.name,
                bundle_discount: +(listPrice - price).toFixed(2),
                bundle_discount_percentage: +(1 - price / listPrice).toFixed(2),
              }),
            },
          ],
        },
      })
      if (!fetchRef.current) {
        const getLoyaltyData = async () => {
          const loyaltyMultiplier = await getLoyaltyMultiplier(sku)
          const [, multiplier] = loyaltyMultiplier.split('-')
          setLoyaltyMultiplier(Number(multiplier))
        }

        getLoyaltyData()
        fetchRef.current = true
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sku])

  const loyaltyPoints = loyaltyMultiplier
    ? Math.trunc(priceInfo.verifiedPriceToShow) * loyaltyMultiplier
    : undefined

  // We fix the GTIN for kits
  const hasKit =
    stockKeepingUnit?.isKit &&
    stockKeepingUnit?.kitComponents &&
    stockKeepingUnit?.kitComponents.length > 0
  const kitComponentsGtin = hasKit && stockKeepingUnit?.kitComponents?.join(',')
  const fixedGtin = !hasKit ? gtin : `${gtin},${kitComponentsGtin}`

  return (
    <>
      <BreadcrumbPDP breadcrumbList={breadcrumbList} />
      <div
        className="relative w-full max-w-[1560px] mx-auto main-content-container-col-JS"
        data-testid="private-label-moltiplicator"
        data-private-label-moltiplicator-value={loyaltyMultiplier}
      >
        <div className="flex flex-col lg:min-h-[655px] main-content-col-JS">
          <div className="px-4 md:pl-10 md:pr-0 xl:pr-10 md:max-w-[calc((100vw/12)*7-40px)] xl:ml-[calc((100vw/12)*5-40px)] xl:mr-[calc((100vw/12)*3.5-40px)] 3xl:w-[calc((1600px/12)*3.5-40px)] 3xl:ml-[calc((1600px/12)*5)] 3xl:mr-[calc((1600px/12)*3.5)] 3xl:px-5">
            <BadgesPDP
              isSubscriptionEligible={hasSubscription}
              badges={productBadges}
              loyaltyPoints={loyaltyPoints}
            />

            <TitlePDP product={{ ...staleProduct, gtin: fixedGtin }} />
          </div>

          <ImagePDP product={staleProduct} />

          {isAvailable && (
            <PricePDP
              priceInfo={priceInfo}
              hasSubscription={hasSubscription}
              pricePerUnit={stockKeepingUnit?.pricePerUnit}
            />
          )}

          <div className="pb-12 md:pb-6">
            {isAvailable ? (
              <Purchase
                product={staleProduct}
                priceInfo={priceInfo}
                openAddToCart={openAddToCart}
                handleOpenCloseAddToCart={handleOpenCloseAddToCart}
                setIsAddedFromSticky={setIsAddedFromSticky}
                isAddedFromSticky={isAddedFromSticky}
                loyaltyMultiplier={loyaltyMultiplier ?? 0}
              />
            ) : (
              <OutOfStockProduct
                variations={hasVariant}
                defaultSku={defaultSku}
                price={price}
                seller={seller}
              />
            )}
            {hasSubscription && <SubscriptionInfo />}
          </div>

          {Object.keys(additionalPropertiesToShow).length !== 0 && (
            <div className="md:max-w-[calc((100vw/12)*7-40px)] xl:ml-[calc((100vw/12)*5-40px)] xl:mr-[calc((100vw/12)*3.5-40px)] 3xl:w-[calc((1600px/12)*3.5-40px)] 3xl:ml-[calc((1600px/12)*5)] 3xl:mr-[calc((1600px/12)*3.5)] md:pl-10 md:pr-0 xl:pr-10 3xl:px-5">
              <div className="border-t-4 border-[#F2F4F5]">
                <div className="px-4 md:px-0 space-y-3 pt-12 md:pt-3">
                  <SpecificationsPDP
                    additionalProperties={additionalPropertiesToShow}
                  />
                  {/* <AdditionalDetails /> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isAvailable && (
        <StickyCart
          product={staleProduct}
          priceInfo={priceInfo}
          handleOpenCloseAddToCart={handleOpenCloseAddToCart}
          setIsAddedFromSticky={setIsAddedFromSticky}
        />
      )}
      <AttributesPDP product={{ ...staleProduct, gtin: fixedGtin }} />
    </>
  )
}

export const fragment = graphql`
  fragment ProductDetailsFragment_product on StoreProduct {
    id: productID
    slug
    sku
    brand {
      brandName: name
    }
    productBadges {
      id
      name
    }
    productSpecifications {
      itemId
      measurementUnit
      unitMultiplier
    }
    availableProducts {
      availableQuantity
      videos
    }
    name
    gtin
    description
    structuredData
    fixedPrices {
      tradePolicyId
      value
      listPrice
      minQuantity
      dateRange {
        from
        to
      }
    }
    isVariantOf {
      name
      productGroupID
      additionalProperty {
        propertyID
        name
        value
        valueReference
      }
      hasVariant {
        ...skuFragment
        image {
          alternateName
          url
        }
      }
    }
    additionalProperty {
      propertyID
      name
      value
      valueReference
    }
    image {
      url
      alternateName
    }
    brand {
      name
    }
    offers {
      lowPrice
      offers {
        availability
        price
        listPrice
        quantity
        seller {
          identifier
        }
      }
    }

    stockKeepingUnit {
      attachments {
        subscriptions {
          id
          name
          keys
          fields {
            fieldName
            maxCaracters
            domainValues
          }
          isActive
          isRequired
        }
      }
      pricePerUnit {
        multiplier
        unit
      }
      clickAndCollect
      isKit
      kitComponents
    }

    breadcrumbList {
      itemListElement {
        item
        name
        position
      }
    }
  }
`

export default memo(NewProductDetails)
